import TlsDepreciationMessageBanner from "../components/TlsDepreciationMessageBanner";
import PreventUserLoginBanner from "../components/PreventUserLoginBanner";
import CookieSettingsModal from "../components/CookieSettingsModal";
import FeatureManager from "../utilities/FeatureManager";
import Cookies from "../utilities/Cookies";

$(document).ready(function () {
    if (!!window.OverDrive.EnableTlsSiteMessage === true) {
        var tlsBanner = new TlsDepreciationMessageBanner();
    }
    var preventLoginBanner = new PreventUserLoginBanner();

    $(".cookies-modal-button, .cs-footer_cookie-modal").on('click', () => {
        var odTrackCookie = Cookies.get("od_track");
        var allowPerformanceCookie = true;
        var allowBehaviorCookie = true;

        if (odTrackCookie == 1 || odTrackCookie == 0) {
            allowBehaviorCookie = false;
        }

        if (odTrackCookie == 2 || odTrackCookie == 0) {
            allowPerformanceCookie = false;
        }

        var modal = new CookieSettingsModal(allowPerformanceCookie, allowBehaviorCookie, true);
    });

    if (!!window.OverDrive.UsingContentStackFooter === true) {
        console.log("using content stack footer ");

        $(".site-footer__section .headerContainer").keypress((e) => {
            var key = e.which;
            if (key == 13 || key == 32)  // the enter and space key codes
            {
                $(e.currentTarget).click();
                e.preventDefault();
                return false;
            }
        }); 

        $(".site-footer__section .headerContainer").on("click", (e) => {
            var target = $(e.currentTarget.parentElement);

            var items = target.find("ul.site-footer__list");
            var openTab = $("ul.site-footer__list:not(.footer-items-hide)");

            if (items.hasClass("footer-items-hide")) {
                if (openTab.length > 0) {
                    openTab.first().addClass("footer-items-hide");
                }
                items.removeClass("footer-items-hide");
            } else {
                items.addClass("footer-items-hide");
            }

        });
    }

    if (!!window.OverDrive.AddLibbyBlogTab === true) {
        $(".u_search__query").on("input", () => {
            if (!$(".tt-dropdown-menu").hasClass("libbyBlogLinkStylingUpdates")) {
                $(".tt-dropdown-menu").addClass("libbyBlogLinkStylingUpdates")
            }
        });
    }

    window.OverDrive.featureManager = new FeatureManager(window.OverDrive.FeatureFlags);
});